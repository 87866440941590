import mainBg from "./mainBg.png";
import ts1 from "./ts1.png";
import ts2 from "./ts2.png";
import ts3 from "./ts3.png";
import ts4 from "./ts4.png";
import ts5 from "./ts5.png";
import ts6 from "./ts6.png";
import ts7 from "./ts7.png";
import noImage from "./noImage.jpg";

const IMAGES = {
  NO_IMAGES: noImage,
  MAIN_BG: mainBg,
  TS1: ts1,
  TS2: ts2,
  TS3: ts3,
  TS4: ts4,
  TS5: ts5,
  TS6: ts6,
  TS7: ts7,
};
export default IMAGES;

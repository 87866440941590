/* eslint-disable */
import api from "../../../utils/api";

const getProductsDetailById = async (data) => {
  const response = await api.get(`/api/products/${data}?populate=*`);
  return response.data.data;
};
// const remainingFamilyMemberStatus = async (data) => {
//   const response = await api.get(
//     "api/FamilyMember/remainingFamilyMemberStatus",
//     {
//       params: data,
//     }
//   );
//   return response.data.data;
// };
const getAllProductsDetail = async () => {
  const response = await api.get("/api/products?populate=* ");
  return response.data;
};

const uploadImages = async (data) => {
  const response = await api.post("/api/upload/", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const addProductsDetail = async (data) => {
  const response = await api.post(`api/products`, data);
  return response.data;
};

const updateProductsDetail = async (data, productId) => {
  const response = await api.put(`/api/products/${productId}`, data);
  return response.data;
};

const deleteProduct = async (productId) => {
  const response = await api.delete(`/api/products/${productId}`);
  return response.data;
};

const ProductsDetailServices = {
  getProductsDetailById,
  deleteProduct,
  //   changeFamilyMemberRole,
  //   remainingFamilyMemberStatus,
  uploadImages,
  addProductsDetail,
  updateProductsDetail,
  getAllProductsDetail,
};

export default ProductsDetailServices;

import { Button } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PATH from "../../../utils/path";
import { logoutAction } from "../../../app/features/auth/auth.slice";

function Index() {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  useEffect(() => {
    onClose();
  }, [location?.pathname]);
  return (
    <div className="container d-flex justify-content-between align-items-center py-3">
      <div
        className="fs-lg fw-light cursor-pointer"
        role="presentation"
        onClick={showDrawer}
      >
        menu
      </div>
      {/* <Drawer
        closable={false}
        placement="left"
        onClose={onClose}
        open={open}
        className="menu"
      > */}
      <div className={`custom-left-drawer ${open ? "d-block" : ""}`}>
        <div>
          <div className="d-flex align-items-baseline">
            <div className="fs-lg fw-light">menu</div>
            <div
              className="text-danger cursor-pointer"
              onClick={onClose}
              role="presentation"
              style={{ marginLeft: "0.8rem" }}
            >
              close
            </div>
          </div>
          <p
            className="m-0  mt-3 fs-sm fw-light text-black cursor-pointer"
            onClick={() => {
              navigate(PATH.ADMIN_ALL_PRODUCTS);
              onClose();
            }}
            role="presentation"
          >
            All Products
          </p>
          <p
            className="m-0 fs-sm fw-light text-black cursor-pointer"
            onClick={() => {
              navigate(PATH.ADMIN_ORDERS);
              onClose();
            }}
            role="presentation"
          >
            Orders
          </p>
          <Button
            type="primary"
            className="mt-2"
            onClick={() => handleLogout()}
          >
            logout
          </Button>
        </div>
      </div>
      {/* </Drawer> */}
      <div
        className="fs-xl fw-light ls-md cursor-pointer"
        onClick={() => navigate(PATH.HOME)}
        role="presentation"
      >
        sentient stay
      </div>
      <div />
    </div>
  );
}

export default Index;

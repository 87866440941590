import AddUpdateProduct from "./AddUpdateProduct.jsx";
import Index from "./Index.jsx";
import Orders from "./Orders.jsx";
import UpdateProducts from "./UpdateProduct.jsx";

const ADMIN_PAGES = {
  ALL_PRODUCTS: Index,
  ORDERS: Orders,
  ADD_UPDATE_PRODUCT: AddUpdateProduct,
  UPDATE_PRODUCT: UpdateProducts,
};
export default ADMIN_PAGES;

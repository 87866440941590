/* eslint-disable */
import { useEffect, useState } from "react";
import { Col, Row, message } from "antd";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getProductsDetailByIdAction } from "../app/features/ProductsDetail/ProductsDetail.slice";
import {
  addToCart,
  getTotals,
  openCartAction,
} from "../app/features/cart/cart.slice";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      role="presentation"
      className="arrow-right-parent py-2"
    >
      <div className="arrow-right d-md-block d-none" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      role="presentation"
      className="arrow-left-parent py-2"
    >
      <div className="arrow-left d-md-block d-none" />
    </div>
  );
}
function Product() {
  const [selectedSize, setSelectedSize] = useState();
  const params = useParams();
  const { id } = params;

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { getProductsDetailById } = useSelector(
    (state) => state.productsDetail
  );

  const product = getProductsDetailById?.attributes;

  const sizes =
    getProductsDetailById?.attributes?.size_chart_ref?.data?.attributes?.sizes;

  const quantityAvailable =
    getProductsDetailById?.attributes?.quantityAvailable;

  const handleAddToCart = (product) => {
    if (!selectedSize) {
      message.error(`please select a size first`);
      return;
    }
    const foundProduct = cart?.cartItems?.find((product) => product.id === id);

    if (
      !foundProduct ||
      !foundProduct?.cartQuantity?.find((item) => item.size === selectedSize)
        ?.quantity
    ) {
      const finalData = {
        ...product,
        id,
        selectedSize,
      };
      dispatch(addToCart(finalData));
      dispatch(openCartAction());
    } else {
      if (
        foundProduct?.cartQuantity?.find((item) => item.size === selectedSize)
          ?.quantity < product.quantityAvailable[selectedSize]
      ) {
        const finalData = {
          ...product,
          id,
          selectedSize,
        };
        dispatch(addToCart(finalData));
        dispatch(openCartAction());
      } else {
        message.error(`${product.title} is not available in selected size`);
      }
    }
  };
  useEffect(() => {
    dispatch(getProductsDetailByIdAction(id));
    dispatch(getTotals());
  }, [dispatch, id]);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => <div className="bottom-bars">{dots}</div>,
    customPaging: (i) => <div className="bottom-bars" />,
  };

  return (
    <Row className="h-100 d-flex justify-content-center">
      <Col sm={22} xl={18}>
        <Row gutter={24}>
          <Col md={24} lg={8} xl={10}>
            <div className="d-flex align-items-baseline mb-4 ">
              <div className="fs-sm text-light-gray fw-light mr-2">
                {getProductsDetailById?.id}
              </div>
              <div className="fs-lg-static fw-light text-black truncate mr-2">
                {getProductsDetailById?.attributes?.title}
              </div>
              <div className="fs-lg fw-light text-black">
                {`$${getProductsDetailById?.attributes?.price}`}
              </div>
            </div>
            <Slider {...settings} className="mt-4 product-carousel">
              {getProductsDetailById?.attributes?.images?.map((item) => (
                <img
                  width="100%"
                  className="pt-5 pb-3"
                  src={item.url}
                  alt="carousel"
                />
              ))}
            </Slider>
          </Col>
          <Col md={24} lg={16} xl={14} className="px-md-5 px-3">
            <Row className="d-flex align-items-end h-100">
              <Col
                xs={{ order: 1 }}
                lg={{ order: 1 }}
                className="mt-lg-0 mt-4 w-100"
              >
                <div className="fs-sm text-black">about</div>
                <div className="fs-xs fw-light ">
                  {getProductsDetailById?.attributes?.about}
                </div>
              </Col>
              <Col
                xs={{ order: 3 }}
                lg={{ order: 2 }}
                className="w-100 my-lg-0 my-3"
              >
                {/* <div className="fs-sm text-black">price</div> */}
                <Row gutter={12}>
                  <Col xs={24} md={12}>
                    <div className="fs-sm text-black">details</div>
                    <ul className="mt-3">
                      <li className="fs-xs fw-light">{`material: ${getProductsDetailById?.attributes?.details?.material}`}</li>
                      <li className="fs-xs fw-light">{`neckline: ${getProductsDetailById?.attributes?.details?.neckline}`}</li>
                      <li className="fs-xs fw-light">{`fit: ${getProductsDetailById?.attributes?.details?.fit}`}</li>
                      {getProductsDetailById?.attributes?.details?.other && (
                        <li className="fs-xs fw-light">
                          {getProductsDetailById?.attributes?.details?.other}
                        </li>
                      )}
                    </ul>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="fs-sm text-black">sizing info</div>
                    <table className="details-table fs-xs fw-light w-100 mt-3">
                      <tr>
                        <th />
                        <th className="fs-xs fw-light">pit ot pit</th>
                        <th className="fs-xs fw-light">length</th>
                      </tr>{" "}
                      {sizes &&
                        Object.entries(sizes).map(([size, measurements]) => (
                          <tr key={size}>
                            <td>{size}</td>
                            <td>{measurements[0]}in</td>
                            <td>{measurements[1]}in</td>
                          </tr>
                        ))}
                    </table>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={{ order: 2 }}
                lg={{ order: 3 }}
                className=" mt-lg-0 mt-3 justify-self-end w-100"
              >
                <Row className="w-100 d-flex align-items-baseline justify-content-between">
                  <Col
                    xs={24}
                    flex="none"
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div
                      className={`${
                        quantityAvailable?.sm > 0 && selectedSize === "sm"
                          ? "fw-normal"
                          : ""
                      } sizes-container px-2 fs-lg-static fw-light mr-4 ml-1 cursor-pointer text-gray ${
                        quantityAvailable?.sm > 0 ? "" : "disabled"
                      }`}
                      onClick={() => setSelectedSize("sm")}
                    >
                      s
                    </div>
                    <div
                      className={`${
                        quantityAvailable?.md > 0 && selectedSize === "md"
                          ? "fw-normal"
                          : ""
                      } sizes-container px-2 fs-lg-static fw-light mr-4 ml-1 cursor-pointer text-gray ${
                        quantityAvailable?.md > 0 ? "" : "disabled"
                      }`}
                      onClick={() => setSelectedSize("md")}
                    >
                      m
                    </div>
                    <div
                      className={`${
                        quantityAvailable?.lg > 0 && selectedSize === "lg"
                          ? "fw-normal"
                          : ""
                      } sizes-container px-2 fs-lg-static fw-light mr-4 ml-1 cursor-pointer text-gray ${
                        quantityAvailable?.lg > 0 ? "" : "disabled"
                      }`}
                      onClick={() => setSelectedSize("lg")}
                    >
                      l
                    </div>
                    <div
                      className={`${
                        quantityAvailable?.xl > 0 && selectedSize === "xl"
                          ? "fw-normal"
                          : ""
                      } sizes-container px-2 fs-lg-static fw-light mr-4 ml-1 cursor-pointer text-gray ${
                        quantityAvailable?.xl > 0 ? "" : "disabled"
                      }`}
                      onClick={() => setSelectedSize("xl")}
                    >
                      xl
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    flex="none"
                    className="fs-lg-static text-gray fw-light text-adjust mt-md-0 mt-2 cursor-pointer"
                    onClick={() => handleAddToCart(product)}
                  >
                    add to cart
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

SamplePrevArrow.propTypes = {
  onClick: PropTypes.node.isRequired,
};

SampleNextArrow.propTypes = {
  onClick: PropTypes.node.isRequired,
};

export default Product;

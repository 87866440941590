const PATH = {
  NOPAGE: "*",

  //  AUTH PATHS
  LOGIN: "/admin/login",
  HOME: "/",
  PRODUCT: "/product/:id",

  FAQ: "/home/faq",
  CONTACT: "/contact",
  ADMIN_ALL_PRODUCTS: "/admin/allproducts",
  ADMIN_ORDERS: "/admin/orders",
  ADMIN_ADD_PRODUCT: "/admin/addproduct",
  ADMIN_UPDATE_PRODUCT: "/admin/updateproduct/:id",
  PAYMENT_SUCCESS: "/order/paymentsuccess",
  PAYMENT_FAILED: "/order/paymentfailed",
};

export default PATH;

import PropTypes from "prop-types";
import Header from "./AdminHeader";

function PublicLayout({ children }) {
  return (
    <div className="home-parent signing-input-fields vh-100 main-bg-image align-items-center pages-component">
      <Header />
      <div className="container scroll-within pt-4">{children}</div>
    </div>
  );
}
PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default PublicLayout;

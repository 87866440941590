/* eslint-disable */
import axios from "axios";
// import { toast } from "react-toastify";

const user = JSON.parse(localStorage.getItem("sentientstay_user"));

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
if (user) headers.Authorization = `Bearer ${user?.jwt}`;

const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL ||
    "https://sea-turtle-app-28qpu.ondigitalocean.app",
  headers,
});

export const makePaymentRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${user?.jwt}`,
  },
});

export const chechout = axios.create({
  baseURL: "https://api.stripe.com/v1",
  headers: {
    // "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.STRIPE_KEY}`,
  },
});

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       window.location.href = "/";
//     }

//     let { message } = error.response.data;

//     if (!message) {
//       message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//     }

//     const errors = {
//       errors: error.response.data.errors,
//       message,
//     };

//     toast.error(message);

//     throw errors;
//   }
// );

export default api;

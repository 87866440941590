/* eslint-disable */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProductsDetailServices from "./ProductsDetail.services";

const initialState = {
  getAllProductsDetail: [],
  addProductsDetail: [],
  getProductsDetailById: [],
  deleteProduct: [],
  uploadImages: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  isUploading: false,
  message: "",
};

export const addProductsDetailAction = createAsyncThunk(
  "productsDetail/addProductsDetailAction",
  async ({ finalData, Notification, moveToNext }, thunkAPI) => {
    try {
      const response = await ProductsDetailServices.addProductsDetail(
        finalData
      );
      if (response) {
        if (Notification) {
          Notification("Product added successfully!", "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notification("failed to add product!","error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const updateProductsDetailAction = createAsyncThunk(
  "productsDetail/updateProductsDetailAction",
  async ({ finalData,productId, Notification, moveToNext }, thunkAPI) => {
    try {
      const response = await ProductsDetailServices.updateProductsDetail(
        finalData,productId
      );
      if (response) {
        if (Notification) {
          Notification("Product updated successfully!", "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notification("failed to update product!","error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteProductAction = createAsyncThunk(
  "productsDetail/deleteProductAction",
  async ({ productId, Notification, moveToNext }, thunkAPI) => {
    try {
      const response = await ProductsDetailServices.deleteProduct(productId);
      if (response) {
        if (Notification) {
          Notification("success");
        }
        if (moveToNext) {
          moveToNext();
        }
        thunkAPI.dispatch(getAllProductsDetailAction());
      } else {
        Notification("error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uploadImagesAction = createAsyncThunk(
  "productsDetail/uploadImagesAction",
  async ({ formData, ImageUploadNotification, moveToNext }, thunkAPI) => {
    try {
      const response = await ProductsDetailServices.uploadImages(formData);
      if (response) {
        if (ImageUploadNotification) {
          ImageUploadNotification("success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        ImageUploadNotification("error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllProductsDetailAction = createAsyncThunk(
  "productsDetail/getAllProductsDetailAction",
  async (_, thunkAPI) => {
    try {
      return await ProductsDetailServices.getAllProductsDetail();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getProductsDetailByIdAction = createAsyncThunk(
  "ProductsDetail/getProductsDetailByIdAction",
  async (data, thunkAPI) => {
    try {
      return await ProductsDetailServices.getProductsDetailById(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const productsDetailSlice = createSlice({
  name: "productsDetail",
  initialState,
  reducers: {
    clearProductsDetail: (state) => {
      state.getAllProductsDetail = [];
      state.addProductsDetail = [];
      state.uploadImages = [];
      state.getProductsDetailById = [];
      state.deleteProduct = [];
      state.isLoading = false;
      state.isUploading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProductsDetailAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProductsDetailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addProductsDetail = action.payload;
      })
      .addCase(addProductsDetailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(uploadImagesAction.pending, (state) => {
        state.isUploading = true;
      })
      .addCase(uploadImagesAction.fulfilled, (state, action) => {
        state.isUploading = false;
        state.isSuccess = true;
        state.uploadImages = action.payload;
      })
      .addCase(uploadImagesAction.rejected, (state, action) => {
        state.isUploading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getProductsDetailByIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsDetailByIdAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getProductsDetailById = action.payload;
      })
      .addCase(getProductsDetailByIdAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllProductsDetailAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProductsDetailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllProductsDetail = action.payload;
      })
      .addCase(getAllProductsDetailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteProductAction.pending, (state) => {
        state.deleteisLoading = true;
      })
      .addCase(deleteProductAction.fulfilled, (state, action) => {
        state.deleteisLoading = false;
        state.isSuccess = true;
        state.deleteProduct = action.payload;
      })
      .addCase(deleteProductAction.rejected, (state, action) => {
        state.deleteisLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearProductsDetail } = productsDetailSlice.actions;

export default productsDetailSlice.reducer;

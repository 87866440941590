/* eslint-disable */
import PropTypes from "prop-types";
import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import PATH from "../../utils/path";
import { loadStripe } from "@stripe/stripe-js";
import {
  closeCartAction,
  decreaseCart,
  getTotals,
  increaseCart,
  removeFromCart,
  // toggleCartOpen,
} from "../../app/features/cart/cart.slice";
import { Loader1 } from "../../components/Layout/Loader/FullScreenLoader";
import { createOrderAction } from "../../app/features/Order/Order.slice";

function IncreaseCartButton({ product }) {
  const dispatch = useDispatch();

  const handleIncreaseCart = () => {
    if (
      product?.cartQuantity?.find((item) => item.size === product.selectedSize)
        ?.quantity < product.quantityAvailable[product.selectedSize]
    ) {
      dispatch(increaseCart(product));
    } else {
      message.error(`${product.title} is not available in selected size`);
    }
  };

  return (
    <AiOutlinePlus
      size={12}
      style={{ marginRight: "0.6rem" }}
      className="cursor-pointer"
      onClick={handleIncreaseCart}
    />
  );
}

function DecreaseCartButton({ product }) {
  const dispatch = useDispatch();

  const handleDecreaseCart = () => {
    dispatch(decreaseCart(product));
  };

  return (
    <AiOutlineMinus
      size={12}
      style={{ marginRight: "0.6rem", marginLeft: "0.6rem" }}
      className="cursor-pointer"
      onClick={handleDecreaseCart}
    />
  );
}

function RemoveFromCartButton({ product }) {
  const dispatch = useDispatch();

  const handleRemoveFromCart = () => {
    dispatch(removeFromCart(product));
  };

  return (
    <div
      className="text-danger d-flex cursor-pointer"
      role="presentation"
      onClick={handleRemoveFromCart}
    >
      remove
    </div>
  );
}

function PublicLayout({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();

  console.log("location.pathname", location.pathname);
  const [open, setOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
    setCartOpen(false);
  };
  const onClose = () => {
    setOpen(false);
  };

  const showCartDrawer = () => {
    setCartOpen(true);
    setOpen(false);
  };
  const onCartClose = () => {
    setCartOpen(false);
  };

  const cart = useSelector((state) => state.cart);
  const { isLoading } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
  );

  const moveToNext = async (response) => {
    const stripe = await stripePromise;
    // const session = await stripe.checkout.sessions.retrieve(response.id);
    await stripe.redirectToCheckout({ sessionId: response.id });
  };

  const handlePayment = async () => {
    const finalData = {
      data: {
        products: cart?.cartItems,
      },
    };
    dispatch(createOrderAction({ finalData, moveToNext }));
  };

  useEffect(() => {
    if (cart?.isCartOpen) {
      showCartDrawer();
    }
  }, [cart]);

  useEffect(() => {
    onClose();
    onCartClose();
  }, [location.pathname]);

  return (
    <div className="home-parent vh-100 signing-input-fields vh-100 main-bg-image align-items-center pages-component">
      <div className="container d-flex justify-content-between align-items-center py-3">
        {location?.pathname !== "/admin/login" ? (
          <>
            <div
              className="fs-lg fw-light cursor-pointer"
              role="presentation"
              onClick={showDrawer}
            >
              menu
            </div>
            <div className={`custom-left-drawer ${open ? "d-block" : ""}`}>
              <div className="d-flex align-items-baseline">
                <div className="fs-lg fw-light">menu</div>
                <div
                  className="text-danger cursor-pointer"
                  onClick={onClose}
                  role="presentation"
                  style={{ marginLeft: "0.8rem" }}
                >
                  close
                </div>
              </div>
              <p
                className="m-0  mt-3 fs-sm fw-light text-black cursor-pointer"
                onClick={() => {
                  navigate(PATH.HOME);
                  onClose();
                }}
                role="presentation"
              >
                home
              </p>
              <p
                className="m-0 fs-sm fw-light text-black cursor-pointer"
                onClick={() => {
                  navigate(PATH.CONTACT);
                  onClose();
                }}
                role="presentation"
              >
                contact
              </p>
              <p
                className="m-0 fs-sm fw-light text-black cursor-pointer"
                onClick={() => {
                  navigate(PATH.FAQ);
                  onClose();
                }}
                role="presentation"
              >
                faq
              </p>
            </div>
            <div
              className="fs-xl fw-light ls-md cursor-pointer"
              onClick={() => navigate(PATH.HOME)}
              role="presentation"
            >
              sentient stay
            </div>
            <div
              className="fs-lg fw-light cursor-pointer"
              role="presentation"
              onClick={showCartDrawer}
            >
              cart
            </div>
            <div className={`custom-right-drawer ${cartOpen ? "d-block" : ""}`}>
              <div
                className="variable-scroll"
                style={{ height: "calc(100vh - 140px)" }}
              >
                <div className="d-flex align-items-baseline">
                  <div className="fs-lg fw-light">cart</div>
                  <div
                    className="text-danger cursor-pointer"
                    onClick={onCartClose}
                    role="presentation"
                    style={{ marginLeft: "0.8rem" }}
                  >
                    close
                  </div>
                </div>
                {cart?.cartItems.map((item) => (
                  <div>
                    <div className="d-flex align-items-baseline justify-content-between">
                      <div className="fs-sm fw-light">{item?.title}</div>
                      <div className="fs-lg fw-light">{item?.price}</div>
                    </div>
                    {item?.cartQuantity.map((qty) => (
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="text-light-gray ">
                          size: {qty?.size}
                        </div>
                        <div className="text-light-gray d-flex align-items-center fs-light">
                          <IncreaseCartButton
                            product={{ ...item, selectedSize: qty?.size }}
                            cart={cart}
                          />
                          {qty?.quantity}
                          <DecreaseCartButton
                            product={{ ...item, selectedSize: qty?.size }}
                          />
                        </div>
                      </div>
                    ))}
                    <div className="w-100 d-flex justify-content-end">
                      <RemoveFromCartButton product={item} />
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <div className="w-100 d-flex justify-content-between">
                  <p className="m-0 fs-sm fw-light text-black">subtotal:</p>
                  <p className="m-0 fs-sm fw-light text-black">
                    {`$${cart?.cartTotalAmount}`}
                  </p>
                </div>
                <p
                  className="m-0 fs-sm text-black underline cursor-pointer disabled"
                  onClick={() => handlePayment()}
                >
                  checkout
                </p>
              </div>
            </div>
          </>
        ) : (
          <div
            className="fs-xl fw-light ls-md w-100 text-center cursor-pointer"
            role="presentation"
            onClick={() => navigate(PATH.HOME)}
          >
            sentient stay
          </div>
        )}
      </div>
      {isLoading ? (
        <div className="container scroll-within pt-4 100vh">
          <Loader1 />
        </div>
      ) : (
        <div className="container scroll-within pt-lg-4 pt-0">{children}</div>
      )}
    </div>
  );
}
PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default PublicLayout;

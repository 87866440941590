/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Upload,
  InputNumber,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addProductsDetailAction,
  uploadImagesAction,
} from "../../app/features/ProductsDetail/ProductsDetail.slice";
import PATH from "../../utils/path";
import { createSizeChartAction } from "../../app/features/SizeChart/SizeChart.slice";
import { Loader1 } from "../../components/Layout/Loader/FullScreenLoader";

const { TextArea } = Input;

function AddUpdateProducts() {
  const [data, setData] = useState();
  const [allUploadedImages, setAllUploadedImages] = useState([]);
  const { uploadImages, isUploading, isLoading } = useSelector(
    (state) => state.productsDetail
  );
  const { createSizeChart, sizeChartLoading } = useSelector(
    (state) => state.sizeChart
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function moveToNext() {
    navigate(PATH.ADMIN_ALL_PRODUCTS);
  }

  function Notification(notification, type) {
    if (type === "success") {
      message.success(notification);
    } else {
      message.error(notification);
    }
  }

  const onFinish = async (values) => {
    const sizeChartData = {
      data: {
        sizes: {
          sm: [values.smChest, values.smLength],
          md: [values.mdChest, values.mdLength],
          lg: [values.lgChest, values.lgLength],
          xl: [values.xlChest, values.xlLength],
        },
      },
    };
    dispatch(createSizeChartAction({ sizeChartData }));
    setData(values);
  };

  useEffect(() => {
    if (!sizeChartLoading && createSizeChart.length !== 0) {
      const images = allUploadedImages.map((item) => ({
        id: item.id,
        url: item.url,
      }));
      if (images.length !== 0) {
        const finalData = {
          data: {
            title: data.title,
            about: data.about,
            price: data.price,
            images,
            quantityAvailable: {
              sm: data.smallQty,
              md: data.mediumQty,
              lg: data.largeQty,
              xl: data.xlQty,
            },
            size_chart_ref: createSizeChart.id,
            details: {
              material: data.material,
              neckline: data.neckline,
              fit: data.fit,
              other: data.other,
            },
          },
        };
        dispatch(
          addProductsDetailAction({ finalData, Notification, moveToNext })
        );
      } else {
        message.error("Please select an image");
      }
    }
  }, [createSizeChart]);
  const [fileList, setFileList] = useState([]);
  const onChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const ImageUploadNotification = (type) => {
    if (type === "error") {
      message.error("failed to upload image successfully");
    } else {
      message.success("Image uploaded successfully!");
    }
  };
  const handleImageUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);
      dispatch(uploadImagesAction({ formData, ImageUploadNotification }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 100);
  };

  useEffect(() => {
    if (uploadImages.length !== 0) {
      setAllUploadedImages((prev) => [...prev, uploadImages[0]]);
    }
  }, [uploadImages]);

  return (
    <Row className="d-flex justify-content-center w-100">
      <Col xs={22} md={18} lg={10}>
        <Form
          layout="vertical"
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            label={<div className="text-black">title</div>}
            name="title"
            rules={[
              {
                required: true,
                message: "Please enter title!",
              },
            ]}
          >
            <Input placeholder="enter product title" />
          </Form.Item>

          <Form.Item
            label={<div className="text-black">about</div>}
            name="about"
            rules={[
              {
                //   required: true,
                message: "Please enter about!",
              },
            ]}
          >
            <TextArea rows={4} placeholder="brief product description" />
          </Form.Item>

          <Form.Item
            className="w-100"
            label={<div className="text-black">price</div>}
            name="price"
            rules={[
              {
                required: true,
                message: "Please enter price!",
              },
            ]}
          >
            <InputNumber placeholder="enter price" />
          </Form.Item>

          <Row gutter={12} className="mb-3">
            <Col className="text-black mb-1" xs={24}>
              Quantity
            </Col>
            <Col xs={24} sm={6} className="mb-1">
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">small</div>}
                name="smallQty"
                initialValue="0"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input small qty!",
                //   },
                // ]}
              >
                <Input placeholder="small" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={6} className="mb-1">
              <Form.Item className="mb-1" name="mediumQty" initialValue="0">
                <Input placeholder="medium" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={6} className="mb-1">
              <Form.Item className="mb-1" name="largeQty" initialValue="0">
                <Input placeholder="large" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={6} className="mb-1">
              <Form.Item className="mb-1" name="xlQty" initialValue="0">
                <Input placeholder="extra large" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12} className="mb-3">
            <Col className="text-black mb-1" xs={8}>
              Size chart
            </Col>
            <Col className="text-black mb-1" xs={8}>
              pit to pit
            </Col>
            <Col className="text-black mb-1" xs={8}>
              length
            </Col>
            <Col xs={8} className="d-flex align-items-center">
              <p className="m-0 text-black">small: </p>
            </Col>
            <Col xs={8}>
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">medium</div>}
                name="smChest"
                // rules={[
                //   {
                //     //   required: true,
                //     message: "Please input title!",
                //   },
                // ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">large</div>}
                name="smLength"
                // rules={[
                //   {
                //     //   required: true,
                //     message: "Please input title!",
                //   },
                // ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12} className="mb-3">
            <Col xs={8} className="d-flex align-items-center">
              <p className="m-0 text-black">medium: </p>
            </Col>
            <Col xs={8}>
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">medium</div>}
                name="mdChest"
                // rules={[
                //   {
                //     //   required: true,
                //     message: "Please input title!",
                //   },
                // ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">large</div>}
                name="mdLength"
                // rules={[
                //   {
                //     //   required: true,
                //     message: "Please input title!",
                //   },
                // ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12} className="mb-3">
            <Col xs={8} className="d-flex align-items-center">
              <p className="m-0 text-black">large: </p>
            </Col>
            <Col xs={8}>
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">medium</div>}
                name="lgChest"
                // rules={[
                //   {
                //     //   required: true,
                //     message: "Please input title!",
                //   },
                // ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">large</div>}
                name="lgLength"
                // rules={[
                //   {
                //     //   required: true,
                //     message: "Please input title!",
                //   },
                // ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12} className="mb-3">
            <Col xs={8} className="d-flex align-items-center">
              <p className="m-0 text-black">extra large: </p>
            </Col>
            <Col xs={8}>
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">medium</div>}
                name="xlChest"
                // rules={[
                //   {
                //     //   required: true,
                //     message: "Please input title!",
                //   },
                // ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">large</div>}
                name="xlLength"
                // rules={[
                //   {
                //     //   required: true,
                //     message: "Please input title!",
                //   },
                // ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12} className="mb-3">
            <Col className="text-black mb-1" xs={24}>
              details
            </Col>
            <Col xs={8} className="d-flex align-items-center">
              <p className="m-0 text-black">material: </p>
            </Col>
            <Col xs={16}>
              <Form.Item
                className="mb-1"
                name="material"
                rules={[
                  {
                    required: true,
                    message: "Please input material!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12} className="mb-3">
            <Col xs={8} className="d-flex align-items-center">
              <p className="m-0 text-black">neckline: </p>
            </Col>
            <Col xs={16}>
              <Form.Item
                className="mb-1"
                name="neckline"
                rules={[
                  {
                    required: true,
                    message: "Please input neckline!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12} className="mb-3">
            <Col xs={8} className="d-flex align-items-center">
              <p className="m-0 text-black">fit: </p>
            </Col>
            <Col xs={16}>
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">medium</div>}
                name="fit"
                rules={[
                  {
                    required: true,
                    message: "Please input fit type!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12} className="mb-3">
            <Col xs={8} className="d-flex align-items-center">
              <p className="m-0 text-black">other: </p>
            </Col>
            <Col xs={16}>
              <Form.Item
                className="mb-1"
                // label={<div className="text-black">medium</div>}
                name="other"
                // rules={[
                //   {
                //     //   required: true,
                //     message: "Please input title!",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <div className="text-black mb-2">product images</div>
          <Upload
            customRequest={dummyRequest}
            multiple
            listType="picture-card"
            disabled={isUploading || isLoading}
            name="files"
            fileList={fileList}
            onChange={onChange}
            beforeUpload={handleImageUpload}
            onPreview={onPreview}
          >
            {!isUploading ? fileList.length < 10 && "+ Upload" : <Loader1 />}
          </Upload>
          <Row gutter={8} className="w-100 d-flex justify-content-end">
            <Col className="mt-2 mr-2">
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  className="mt-2"
                  htmlType="submit"
                  disabled={isUploading || isLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default AddUpdateProducts;

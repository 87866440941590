import Contact from "./Contact.jsx";
import Faq from "./Faq.jsx";
import Index from "./Index.jsx";
import NoPageFound from "./NoPageFound.jsx";
import Product from "./Products.jsx";
import ADMIN_PAGES from "./Admin/index.js";
import Login from "./Login.jsx";
import PAYMENT from "./Payment";

const WEB_PAGES = {
  LOGIN: Login,
  HOME: Index,
  PRODUCT: Product,
  CONTACT: Contact,
  FAQ: Faq,
  ADMIN_PAGES,
  PAYMENT,
  NO_PAGE_FOUND: NoPageFound,
};
export default WEB_PAGES;

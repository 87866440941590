import { Col, Row } from "antd";

function Faq() {
  return (
    <Row className="h-100 d-flex justify-content-center">
      <Col sm={20} xl={18}>
        <div className="fs-lg text-light-gray fw-light">
          /<span className="fs-lg fw-light text-black">faq</span>
        </div>
        <Col lg={20} className="pt-4 custom-px">
          <div className="fs-xs-sm">about</div>
          <div className="fs-xs-sm fw-light">
            sentient stay is the realization of some feelings i have in physical
            form.
          </div>
        </Col>
        <Col lg={20} className="pt-4 custom-px">
          <div className="fs-xs-sm">common questions</div>
          <div className="fs-xs-sm fw-light">
            this is a full stack one man operation, from coding the website to
            printing and shipping the shirts. I ask kindly that you offer
            patience if any order issues or delays occur and trust that I will
            do the right thing if something isnt correct
            <br />
            <br />
            all orders will receive tracking and order updates via email
            <br />
            <br />
            all orders will be printed within two days of the order placement
            and then shipped priority within the next two days after that
          </div>
        </Col>
      </Col>
    </Row>
  );
}

export default Faq;

/* eslint-disable */
import api from "../../../utils/api";

const createOrder = async (data) => {
  const response = await api.post("/api/orders", data);
  return response.data.stripeSession;
};

const getOrderById = async (id) => {
  const response = await api.get(`/api/orders/${id}?populate=*`);
  return response;
};

const getAllOrders = async () => {
  const response = await api.get(`/api/orders?populate=*`);
  return response.data.sessions.data;
};
const OrderServices = {
  createOrder,
  getOrderById,
  getAllOrders,
};

export default OrderServices;

import { Col, Row } from "antd";

function PaymentFailed() {
  return (
    <Row>
      <Col>Payment Failed</Col>
    </Row>
  );
}

export default PaymentFailed;

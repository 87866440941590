/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import IMAGES from "../assets/images/index.js";
import PATH from "../utils/path.js";
import { getAllProductsDetailAction } from "../app/features/ProductsDetail/ProductsDetail.slice.js";

function Index() {
  const [hoveredProduct, setHoveredProduct] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Welcome to Sentient stay";
  });
  const handleProductHover = (id) => {
    setHoveredProduct(id);
  };
  // const data = [
  //   {
  //     id: "01",
  //     name: "veillance",
  //     image: IMAGES.TS1,
  //   },
  //   {
  //     id: "02",
  //     name: "veillance",
  //     image: IMAGES.TS2,
  //   },
  //   {
  //     id: "03",
  //     name: "ICISIL",
  //     image: IMAGES.TS3,
  //   },
  //   {
  //     id: "04",
  //     name: "veillance",
  //     image: IMAGES.TS4,
  //   },
  //   {
  //     id: "05",
  //     name: "veillance",
  //     image: IMAGES.TS5,
  //   },
  //   {
  //     id: "06",
  //     name: "veillance",
  //     image: IMAGES.TS6,
  //   },
  //   {
  //     id: "08",
  //     name: "veillance",
  //     image: IMAGES.TS1,
  //   },
  // ];

  const handleClick = (id) => {
    navigate(PATH.PRODUCT.replace(":id", id));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    // let finalData = {
    //   userId: auth?.intely_health_user?.userId,
    // };
    dispatch(getAllProductsDetailAction());
  }, [dispatch]);

  const { getAllProductsDetail } = useSelector((state) => state.productsDetail);

  return (
    <Row gutter={24} className="all-products-wrapper">
      {getAllProductsDetail?.data?.map((item) => (
        <Col
          key={item.id}
          xs={24}
          md={12}
          lg={8}
          xl={6}
          onClick={() => handleClick(item.id)}
          className="cursor-pointer"
        >
          <Card className="mb-4 product-parent">
            <div className="d-flex align-items-center mb-4 px-4 justify-content-between">
              <div className="d-flex align-items-center">
                <div className="fs-sm text-light-gray fw-light mr-2">
                  {item?.id}
                </div>
                <div className="fs-lg fw-light text-black truncate">
                  {item?.attributes?.title}
                </div>
              </div>
              <div className="fs-lg fw-light text-black">
                {`$${item?.attributes?.price}`}
              </div>
            </div>
            <img
              key={item.id}
              className={`product-img ${
                hoveredProduct !== null && hoveredProduct !== item.id
                  ? "greyed-out"
                  : ""
              }`}
              onMouseEnter={() => handleProductHover(item.id)}
              onMouseLeave={() => handleProductHover(null)}
              src={
                (item?.attributes?.images.length !== 0
                  ? item?.attributes?.images[0]?.url
                  : IMAGES.NO_IMAGES) || IMAGES.NO_IMAGES
              }
              alt="products"
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
}
export default Index;

import { configureStore } from "@reduxjs/toolkit";
// import authReducer from "./features/auth/auth.slice";
import productsDetailReducer from "./features/ProductsDetail/ProductsDetail.slice";
import cartSliceReducer from "./features/cart/cart.slice";
import loginReducer from "./features/auth/auth.slice";
import sizeChartReducer from "./features/SizeChart/SizeChart.slice";
import orderReducer from "./features/Order/Order.slice";

const store = configureStore({
  reducer: {
    // auth: authReducer,
    productsDetail: productsDetailReducer,
    cart: cartSliceReducer,
    login: loginReducer,
    sizeChart: sizeChartReducer,
    order: orderReducer,
  },
});

export default store;

/** @format */

import { Routes, Route } from "react-router-dom";
import PublicRoute from "./Routes/PublicRoute";
import WEB_PAGES from "../pages";
import PATH from "../utils/path";
import ProtectedRoute from "./Routes/ProtectedRoute";

function RouterConfig() {
  return (
    <Routes>
      {/* AUTH ROUTES START */}
      <Route
        path={PATH.HOME}
        element={<PublicRoute element={<WEB_PAGES.HOME />} />}
      />
      <Route
        path={PATH.PRODUCT}
        element={<PublicRoute element={<WEB_PAGES.PRODUCT />} />}
      />
      <Route
        path={PATH.FAQ}
        element={<PublicRoute element={<WEB_PAGES.FAQ />} />}
      />
      <Route
        path={PATH.CONTACT}
        element={<PublicRoute element={<WEB_PAGES.CONTACT />} />}
      />
      <Route
        path={PATH.ADMIN_ALL_PRODUCTS}
        element={
          <ProtectedRoute element={<WEB_PAGES.ADMIN_PAGES.ALL_PRODUCTS />} />
        }
      />
      <Route
        path={PATH.ADMIN_ORDERS}
        element={<ProtectedRoute element={<WEB_PAGES.ADMIN_PAGES.ORDERS />} />}
      />
      <Route
        path={PATH.ADMIN_ADD_PRODUCT}
        element={
          <ProtectedRoute
            element={<WEB_PAGES.ADMIN_PAGES.ADD_UPDATE_PRODUCT />}
          />
        }
      />
      <Route
        path={PATH.ADMIN_UPDATE_PRODUCT}
        element={
          <ProtectedRoute element={<WEB_PAGES.ADMIN_PAGES.UPDATE_PRODUCT />} />
        }
      />
      <Route
        path={PATH.PAYMENT_SUCCESS}
        element={
          <PublicRoute element={<WEB_PAGES.PAYMENT.PAYMENT_SUCCESS />} />
        }
      />
      <Route
        path={PATH.PAYMENT_FAILED}
        element={
          <ProtectedRoute element={<WEB_PAGES.PAYMENT.PAYMENT_FAILED />} />
        }
      />
      <Route
        path={PATH.LOGIN}
        element={<PublicRoute element={<WEB_PAGES.LOGIN />} />}
      />
      <Route path={PATH.NOPAGE} element={<WEB_PAGES.NO_PAGE_FOUND />} />
    </Routes>
  );
}

export default RouterConfig;

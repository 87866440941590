/* eslint-disable */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SizeChartServices from "./SizeChart.services";

const initialState = {
  getSizeChartById: [],
  createSizeChart: [],
  updateSizeChart: [],
  isError: false,
  sizeChartLoading: false,
  isSuccess: false,
  message: "",
};

export const createSizeChartAction = createAsyncThunk(
  "sizeChart/createSizeChartAction",
  async ({ sizeChartData }, thunkAPI) => {
    try {
      const response = await SizeChartServices.createSizeChart(sizeChartData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateSizeChartAction = createAsyncThunk(
  "sizeChart/updateSizeChartAction",
  async ({ sizeChartData, chartId }, thunkAPI) => {
    try {
      const response = await SizeChartServices.updateSizeChart(
        sizeChartData,
        chartId
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSizeChartByIdAction = createAsyncThunk(
  "sizeChart/getSizeChartByIdAction",
  async (data, thunkAPI) => {
    try {
      return await SizeChartServices.getSizeChartById(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const SizeChartSlice = createSlice({
  name: "SizeChart",
  initialState,
  reducers: {
    clearSizeChart: (state) => {
      state.createSizeChart = [];
      state.getSizeChartById = [];
      state.updateSizeChart = [];
      state.sizeChartLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSizeChartAction.pending, (state) => {
        state.sizeChartLoading = true;
      })
      .addCase(createSizeChartAction.fulfilled, (state, action) => {
        state.sizeChartLoading = false;
        state.isSuccess = true;
        state.createSizeChart = action.payload;
      })
      .addCase(createSizeChartAction.rejected, (state, action) => {
        state.sizeChartLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateSizeChartAction.pending, (state) => {
        state.sizeChartLoading = true;
      })
      .addCase(updateSizeChartAction.fulfilled, (state, action) => {
        state.sizeChartLoading = false;
        state.isSuccess = true;
        state.updateSizeChart = action.payload;
      })
      .addCase(updateSizeChartAction.rejected, (state, action) => {
        state.sizeChartLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getSizeChartByIdAction.pending, (state) => {
        state.sizeChartLoading = true;
      })
      .addCase(getSizeChartByIdAction.fulfilled, (state, action) => {
        state.sizeChartLoading = false;
        state.isSuccess = true;
        state.getSizeChartById = action.payload;
      })
      .addCase(getSizeChartByIdAction.rejected, (state, action) => {
        state.sizeChartLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearSizeChart } = SizeChartSlice.actions;

export default SizeChartSlice.reducer;

import { Col, Row } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearCart } from "../../app/features/cart/cart.slice";

function PaymentSuccessfull() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCart());
  }, [dispatch]);
  return (
    <Row className="w-100 d-flex justify-content-center">
      <Col>Payment successfull</Col>
    </Row>
  );
}

export default PaymentSuccessfull;

/* eslint-disable */
import { Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersAction } from "../../app/features/Order/Order.slice";

function Index() {
  const dispatch = useDispatch();

  const { getAllOrders } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getAllOrdersAction());
  }, [dispatch]);

  const columns = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
      align: "center",
      width: "100px",
      render: (orderId) => (
        <span className="truncate" style={{ width: "90px" }}>
          {orderId}
        </span>
      ),
    },
    {
      title: "Customer name",
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      width: "150px",
      render: (customerName) => (
        <span className="truncate" style={{ width: "140px" }}>
          {customerName}
        </span>
      ),
    },
    {
      title: "Customer Phone",
      dataIndex: "customerPhone",
      key: "customerPhone",
      align: "center",
      width: "150px",
      render: (customerPhone) => (
        <span className="truncate" style={{ width: "150px" }}>
          {customerPhone}
        </span>
      ),
    },
    {
      title: "Shipping Address",
      dataIndex: "shippingAddress",
      key: "shippingAddress",
      align: "center",
      width: "280px",
      render: (shippingAddress) => (
        <span className="truncate" style={{ width: "280px" }}>
          {shippingAddress}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "80px",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   align: "center",
    // },
    // {
    //   title: "Quantity",
    //   key: "quantity",
    //   dataIndex: "quantity",
    //   align: "center",
    //   width: "150px",
    // },
    {
      title: "Total Bill",
      dataIndex: "totalBill",
      key: "totalBill",
      width: "150px",
      align: "center",
      render: (price) => (
        <span
          className="truncate"
          style={{ width: "150px" }}
        >{`$${price}`}</span>
      ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   align: "center",
    //   render: () => (
    //     <div size="middle">
    //       <FiEdit
    //         size={20}
    //         className="mr-3 cursor-pointer"
    //         onClick={() => setOpen(true)}
    //       />
    //       <AiOutlineDelete size={20} />
    //     </div>
    //   ),
    // },
  ];

  const orderData = getAllOrders?.map((order) => ({
    orderId: order.id,
    customerName: order?.customer_details?.name || "null",
    customerEmail: order?.customer_details?.email || "null",
    customerPhone: order?.customer_details?.phone || "null",
    shippingAddress:
      `${
        !order?.shipping_details?.address?.line1
          ? ""
          : order?.shipping_details?.address?.line1
      } ${
        !order?.shipping_details?.address?.line2
          ? ""
          : order?.shipping_details?.address?.line2
      } ${
        !order?.shipping_details?.address?.city
          ? ""
          : order?.shipping_details?.address?.city
      } ${
        !order?.shipping_details?.address?.state
          ? ""
          : order?.shipping_details?.address?.state
      } ${
        !order?.shipping_details?.address?.state
          ? ""
          : order?.shipping_details?.address?.state
      } ${
        !order?.shipping_details?.address?.country
          ? ""
          : order?.shipping_details?.address?.country
      }` || "null",
    status: order?.payment_status || "null",
    totalBill: order?.amount_total / 100 || "null",
  }));

  return (
    <>
      <Table
        scroll={{
          y: "calc(100vh - 320px)",
        }}
        pagination={{
          pageSize: 10,
        }}
        columns={columns}
        dataSource={orderData}
      />
    </>
  );
}

export default Index;

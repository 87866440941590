import ScaleLoader from "react-spinners/ScaleLoader";
import { useState } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd";
// import { css } from "@emotion/react";

export default function FullScreenLoader() {
  return (
    <div className="loaderDiv">
      <div className="spinner">
        <ScaleLoader
          loading="true"
          // cssOverride={override}
          size={200}
        />
      </div>
    </div>
  );
}

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

export function Loader1() {
  const [loading, setLoading] = useState(true);
  return (
    <div className="d-flex justify-content-center mt-3">
      <Spin
        indicator={antIcon}
        loading={loading}
        size={30}
        fill="#ffffff"
        setLoading={setLoading}
      />
    </div>
  );
}

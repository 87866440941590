/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Upload,
  InputNumber,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProductsDetailByIdAction,
  updateProductsDetailAction,
  uploadImagesAction,
} from "../../app/features/ProductsDetail/ProductsDetail.slice";
import PATH from "../../utils/path";
import { updateSizeChartAction } from "../../app/features/SizeChart/SizeChart.slice";
import { Loader1 } from "../../components/Layout/Loader/FullScreenLoader";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

const { TextArea } = Input;

function UpdateProducts() {
  const { id } = useParams();
  const [data, setData] = useState();
  const [allUploadedImages, setAllUploadedImages] = useState([]);

  const { uploadImages, getProductsDetailById, isUploading, isLoading } =
    useSelector((state) => state.productsDetail);

    const { updateSizeChart, sizeChartLoading } = useSelector(
      (state) => state.sizeChart
    );

    const sizeChartData =
      getProductsDetailById?.attributes?.size_chart_ref?.data?.attributes
        ?.sizes;

    const chartId = getProductsDetailById?.attributes?.size_chart_ref?.data?.id;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function moveToNext() {
      navigate(PATH.ADMIN_ALL_PRODUCTS);
    }

    function Notification(notification, type) {
      if (type === "success") {
        message.success(notification);
      } else {
        message.error(notification);
      }
    }

    const onFinish = async (values) => {
      const sizeChartData = {
        data: {
          sizes: {
            sm: [values.smChest, values.smLength],
            md: [values.mdChest, values.mdLength],
            lg: [values.lgChest, values.lgLength],
            xl: [values.xlChest, values.xlLength],
          },
        },
      };
      dispatch(updateSizeChartAction({ sizeChartData, chartId }));
      setData(values);
    };

    useEffect(() => {
      if (!sizeChartLoading && updateSizeChart.length !== 0) {
        const images = allUploadedImages.map((item) => ({
          url: item.url,
        }));
        if (images.length !== 0) {
          const finalData = {
            data: {
              title: data.title,
              about: data.about,
              price: data.price,
              images,
              quantityAvailable: {
                sm: data.smallQty,
                md: data.mediumQty,
                lg: data.largeQty,
                xl: data.xlQty,
              },
              size_chart_ref: updateSizeChart.id,
              details: {
                material: data.material,
                neckline: data.neckline,
                fit: data.fit,
                other: data.other,
              },
            },
          };
          dispatch(
            updateProductsDetailAction({
              finalData,
              productId: id,
              Notification,
              moveToNext,
            })
          );
        } else {
          message.error("Please select an image");
        }
      }
    }, [updateSizeChart]);

    const removeImage = (file) => {
      setAllUploadedImages((prev) => prev.filter((img) => img.id !== file.id));
    };
    const onPreview = (file) => {
      let src = file.url;
      if (src) {
        window.open(src, "_blank", "noreferrer");
      }
    };

    const ImageUploadNotification = (type) => {
      if (type === "error") {
        message.error("failed to upload image successfully");
      } else {
        message.success("Image uploaded successfully!");
      }
    };
    const handleImageUpload = async (file) => {
      try {
        const formData = new FormData();
        formData.append("files", file);
        dispatch(uploadImagesAction({ formData, ImageUploadNotification }));
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };

    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 100);
    };

    useEffect(() => {
      if (uploadImages.length !== 0) {
        setAllUploadedImages((prev) => [...prev, uploadImages[0]]);
      }
    }, [uploadImages]);

    useEffect(() => {
      dispatch(getProductsDetailByIdAction(id));
    }, [dispatch, id]);

    useEffect(() => {
      if (!isLoading && getProductsDetailById.length !== 0) {
        const modiedImageList = getProductsDetailById?.attributes?.images?.map(
          (item) => ({
            id: item.id,
            url: item.url,
            status: "done",
          })
        );

        setAllUploadedImages(modiedImageList);
      }
    }, [getProductsDetailById]);

    return (
      <Row className="d-flex justify-content-center w-100 update-product-wrapper">
        <Col xs={22} md={18} lg={10}>
          {!isLoading ? (
            <Form
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              requiredMark={false}
            >
              <Form.Item
                label={<div className="text-black">title</div>}
                name="title"
                initialValue={getProductsDetailById?.attributes?.title || ""}
                rules={[
                  {
                    required: true,
                    message: "Please enter title!",
                  },
                ]}
              >
                <Input placeholder="enter product title" />
              </Form.Item>

              <Form.Item
                label={<div className="text-black">about</div>}
                name="about"
                initialValue={getProductsDetailById?.attributes?.about || ""}
                rules={[
                  {
                    required: true,
                    message: "Please enter about!",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="brief product description" />
              </Form.Item>

              <Form.Item
                className="w-100"
                label={<div className="text-black">price</div>}
                name="price"
                initialValue={getProductsDetailById?.attributes?.price || ""}
                rules={[
                  {
                    required: true,
                    message: "Please enter price!",
                  },
                ]}
              >
                <InputNumber placeholder="enter price" />
              </Form.Item>
              <Row gutter={12} className="mb-3">
                <Col className="text-black mb-1" xs={24}>
                  Quantity
                </Col>
                <Col xs={24} sm={6} className="mb-1">
                  <Form.Item
                    className="mb-1"
                    name="smallQty"
                    initialValue={
                      getProductsDetailById?.attributes?.quantityAvailable
                        ?.sm || "0"
                    }
                  >
                    <Input placeholder="small" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} className="mb-1">
                  <Form.Item
                    className="mb-1"
                    name="mediumQty"
                    initialValue={
                      getProductsDetailById?.attributes?.quantityAvailable
                        ?.md || "0"
                    }
                  >
                    <Input placeholder="medium" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} className="mb-1">
                  <Form.Item
                    className="mb-1"
                    name="largeQty"
                    initialValue={
                      getProductsDetailById?.attributes?.quantityAvailable
                        ?.lg || "0"
                    }
                  >
                    <Input placeholder="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} className="mb-1">
                  <Form.Item
                    className="mb-1"
                    name="xlQty"
                    initialValue={
                      getProductsDetailById?.attributes?.quantityAvailable
                        ?.xl || "0"
                    }
                  >
                    <Input placeholder="extra large" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12} className="mb-3">
                <Col className="text-black mb-1" xs={8}>
                  Size chart
                </Col>
                <Col className="text-black mb-1" xs={8}>
                  pit to pit
                </Col>
                <Col className="text-black mb-1" xs={8}>
                  length
                </Col>
                <Col xs={8} className="d-flex align-items-center">
                  <p className="m-0 text-black">small: </p>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    className="mb-1"
                    name="smChest"
                    initialValue={sizeChartData?.sm[0] || 0}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    className="mb-1"
                    name="smLength"
                    initialValue={sizeChartData?.sm[1] || 0}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} className="mb-3">
                <Col xs={8} className="d-flex align-items-center">
                  <p className="m-0 text-black">medium: </p>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    className="mb-1"
                    name="mdChest"
                    initialValue={sizeChartData?.md[0] || 0}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    className="mb-1"
                    name="mdLength"
                    initialValue={sizeChartData?.md[1] || 0}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12} className="mb-3">
                <Col xs={8} className="d-flex align-items-center">
                  <p className="m-0 text-black">large: </p>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    className="mb-1"
                    name="lgChest"
                    initialValue={sizeChartData?.lg[0] || 0}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    className="mb-1"
                    name="lgLength"
                    initialValue={sizeChartData?.lg[1] || 0}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12} className="mb-3">
                <Col xs={8} className="d-flex align-items-center">
                  <p className="m-0 text-black">extra large: </p>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    className="mb-1"
                    name="xlChest"
                    initialValue={sizeChartData?.xl[0] || 0}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    className="mb-1"
                    name="xlLength"
                    initialValue={sizeChartData?.xl[1] || 0}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12} className="mb-3">
                <Col className="text-black mb-1" xs={24}>
                  details
                </Col>
                <Col xs={8} className="d-flex align-items-center">
                  <p className="m-0 text-black">material: </p>
                </Col>
                <Col xs={16}>
                  <Form.Item
                    initialValue={
                      getProductsDetailById?.attributes?.details?.material || ""
                    }
                    className="mb-1"
                    name="material"
                    rules={[
                      {
                        required: true,
                        message: "Please input material!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} className="mb-3">
                <Col xs={8} className="d-flex align-items-center">
                  <p className="m-0 text-black">neckline: </p>
                </Col>
                <Col xs={16}>
                  <Form.Item
                    initialValue={
                      getProductsDetailById?.attributes?.details?.neckline || ""
                    }
                    className="mb-1"
                    name="neckline"
                    rules={[
                      {
                        required: true,
                        message: "Please input neckline!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12} className="mb-3">
                <Col xs={8} className="d-flex align-items-center">
                  <p className="m-0 text-black">fit: </p>
                </Col>
                <Col xs={16}>
                  <Form.Item
                    initialValue={
                      getProductsDetailById?.attributes?.details?.fit || ""
                    }
                    className="mb-1"
                    // label={<div className="text-black">medium</div>}
                    name="fit"
                    rules={[
                      {
                        required: true,
                        message: "Please input fit type!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12} className="mb-3">
                <Col xs={8} className="d-flex align-items-center">
                  <p className="m-0 text-black">other: </p>
                </Col>
                <Col xs={16}>
                  <Form.Item
                    className="mb-1"
                    initialValue={
                      getProductsDetailById?.attributes?.details?.other || ""
                    }
                    // label={<div className="text-black">medium</div>}
                    name="other"
                    // rules={[
                    //   {
                    //     //   required: true,
                    //     message: "Please input title!",
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <div className="text-black mb-2">product images</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {allUploadedImages &&
                  allUploadedImages.map((imgSrc) => (
                    <div className="uploadimage-parent">
                      <div className="overlay">
                        <EyeOutlined
                          className="update-icons cursor-pointer mr-2"
                          onClick={() => onPreview(imgSrc)}
                        />
                        <DeleteOutlined
                          className="update-icons cursor-pointer"
                          onClick={() => removeImage(imgSrc)}
                        />
                      </div>
                      <img
                        src={imgSrc.url}
                        className="uploaded-images mt-2"
                        alt="product"
                      />
                    </div>
                  ))}
                <Upload
                  className="mt-2"
                  multiple
                  customRequest={dummyRequest}
                  // action={"/"}
                  listType="picture-card"
                  name="files"
                  beforeUpload={handleImageUpload}
                  // onPreview={onPreview}
                  disabled={isUploading || isLoading}
                >
                  {!isUploading ? (
                    allUploadedImages?.length < 10 && "+ Upload"
                  ) : (
                    <Loader1 />
                  )}
                </Upload>
              </div>
              <Row gutter={8} className="w-100 d-flex justify-content-end">
                <Col className="mt-2 mr-2">
                  <Button onClick={() => navigate(PATH.ADMIN_ALL_PRODUCTS)}>
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="mt-2"
                      htmlType="submit"
                      disabled={isUploading || isLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            <Loader1 />
          )}
        </Col>
      </Row>
    );
}

export default UpdateProducts;

import { Col, Row } from "antd";

function Contact() {
  return (
    <Row className="h-100 d-flex justify-content-center">
      <Col sm={20} xl={18}>
        <div className="fs-lg text-light-gray fw-light">
          /<span className="fs-lg fw-light text-black">contact</span>
        </div>
        <Col lg={20} className="pt-4 custom-px">
          <div className="fs-xs-sm">comms</div>
          <div className="fs-xs-sm fw-light">
            for any questions or concerns about product or orders help please
            contact at <span className="text-link">help@sentientstay.com</span>
          </div>
        </Col>
      </Col>
    </Row>
  );
}

export default Contact;

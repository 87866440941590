/* eslint-disable */
import api from "../../../utils/api";

const getSizeChartById = async (data) => {
  const response = await api.get(`/api/size-charts/${data}?populate=*`);
  return response.data.data;
};

const updateSizeChart = async (data, chartId) => {
  const response = await api.put(`/api/size-charts/${chartId}`, data);
  return response.data.data;
};

const createSizeChart = async (data) => {
  const response = await api.post(`/api/size-charts`, data);
  return response.data.data;
};

const SizeChartServices = {
  getSizeChartById,
  updateSizeChart,
  createSizeChart,
};

export default SizeChartServices;

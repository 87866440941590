/* eslint-disable */
import { useEffect, useState } from "react";
import { Button, notification, Card, Col, Form, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../app/features/auth/auth.slice";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import PATH from "../utils/path";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeUserData = (data) => {
    localStorage.setItem("sentientstay_user", JSON.stringify(data));
    navigate(PATH.ADMIN_ALL_PRODUCTS);
  };

  const { login } = useSelector((state) => state.login);

  const onFinish = (values) => {
    const user = {
      identifier: values.email,
      password: values.password,
    };
    dispatch(
      loginAction({ user, setLocalStorage: storeUserData, Notification })
    );
  };

  const Notification = (message) => {
    api["error"]({
      message,
      duration: 2,
    });
  };

  useEffect(() => {
    if (login?.jwt) {
      navigate(PATH.ADMIN_ALL_PRODUCTS);
    }
  }, [login?.jwt]);

  return (
    <Row className="h-100 d-flex align-items-center justify-content-center">
      <Col xs={22} sm={18} md={14} lg={8}>
        <Card className="glass-bg p-sm-4 p-3 ">
          <div className="fs-lg mb-3 text-center">Login</div>
          {contextHolder}
          <Form
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item
              label={<div className="text-black">Email</div>}
              name="email"
              className="mt-3 "
              rules={[
                {
                  type: "email",
                  message: "Enter valid email",
                },
                {
                  required: true,
                  message: "Enter valid email",
                },
                {
                  max: 50,
                  message: "email should be below 50!",
                },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item
              label={<div className="text-black">Password</div>}
              className="mt-3 mb-2"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Enter correct password",
                },
                {
                  max: 50,
                  message: "Password should be below 50!",
                },
              ]}
            >
              <Input
                placeholder="Enter your password"
                type={showPassword ? "text" : "password"}
                suffix={
                  showPassword ? (
                    <AiOutlineEye
                      size={18}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      size={18}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )
                }
              />
            </Form.Item>
            <div className="text-purple d-flex justify-content-end w-20 mt-3 mb-3">
              <div
                className="cursor-pointer"
                role="presentation"
                onClick={() => setModalOpen(true)}
              >
                Forgot Password?
              </div>
            </div>
            <Form.Item>
              <Button type="primary" className="w-100" htmlType="submit">
                <span className="font-weight-semibold">Login In</span>
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default Login;

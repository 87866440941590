/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  isCartOpen: false,
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const existingIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingIndex >= 0) {
        const { cartQuantity } = state.cartItems[existingIndex];
        const existingSizeIndex = cartQuantity.findIndex(
          (qty) => qty.size === action.payload.selectedSize
        );

        if (existingSizeIndex >= 0) {
          state.cartItems[existingIndex].cartQuantity[
            existingSizeIndex
          ].quantity += 1;
        } else {
          state.cartItems[existingIndex].cartQuantity.push({
            size: action.payload.selectedSize,
            quantity: 1,
          });
        }
      } else {
        const tempProductItem = {
          ...action.payload,
          cartQuantity: [{ size: action.payload.selectedSize, quantity: 1 }],
        };
        state.cartItems.push(tempProductItem);
      }

      // message.success("Product added to cart");
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    increaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      const { cartQuantity } = state.cartItems[itemIndex];
      const sizeIndex = cartQuantity.findIndex(
        (qty) => qty.size === action.payload.selectedSize
      );

      if (sizeIndex >= 0) {
        state.cartItems[itemIndex].cartQuantity[sizeIndex].quantity += 1;
      } else {
        state.cartItems[itemIndex].cartQuantity.push({
          size: action.payload.selectedSize,
          quantity: 1,
        });
      }

      if (cartQuantity.length === 0) {
        state.cartItems.splice(itemIndex, 1);
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      const { cartQuantity } = state.cartItems[itemIndex];
      const sizeIndex = cartQuantity.findIndex(
        (qty) => qty.size === action.payload.selectedSize
      );

      if (sizeIndex >= 0) {
        if (cartQuantity[sizeIndex].quantity > 1) {
          cartQuantity[sizeIndex].quantity -= 1;
        } else {
          cartQuantity.splice(sizeIndex, 1);
        }
      }

      if (cartQuantity.length === 0) {
        state.cartItems.splice(itemIndex, 1);
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      state.cartItems.map((cartItem) => {
        if (cartItem.id === action.payload.id) {
          const nextCartItems = state.cartItems.filter(
            (item) => item.id !== cartItem.id
          );

          state.cartItems = nextCartItems;
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        // return state;
      });
    },
    getTotals(state) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { price, cartQuantity } = cartItem;

          // Calculate the subtotal for each item
          const itemTotal = cartQuantity.reduce(
            (itemSubtotal, item) => itemSubtotal + price * item.quantity,
            0
          );

          // Update the cart total and quantity
          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity.reduce(
            (itemQuantity, item) => itemQuantity + item.quantity,
            0
          );

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );

      // Round the total to 2 decimal places
      total = parseFloat(total.toFixed(2));

      // Update the state with the calculated totals
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },
    closeCartAction(state) {
      state.isCartOpen = false;
    },

    openCartAction(state) {
      state.isCartOpen = true;
    },

    clearCart(state) {
      state.cartItems = [];
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
  },
});

export const {
  addToCart,
  decreaseCart,
  removeFromCart,
  getTotals,
  clearCart,
  closeCartAction,
  openCartAction,
  increaseCart,
} = cartSlice.actions;

export default cartSlice.reducer;

// import { useEffect } from "react";
// import { useSelector } from "react-redux";
import PropTypes from "prop-types";
// import FullScreenLoader from "../../components/Layout/Loader/FullScreenLoader";
// import { getRoleName } from "../../utils/authenticateUsers";
import PublicLayout from "../../components/Layout/PublicLayout";

function PublicRoute({ element }) {
  // const { user } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   if (user?.token) {
  //     const role = getRoleName(user?.roleId);
  //     window.location.href = `/${role}/dashboard`;
  //   }
  // }, [user]);

  // if (user === undefined) return <FullScreenLoader />;

  return <PublicLayout>{element}</PublicLayout>;
}

PublicRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default PublicRoute;
